<template>
    <div
        v-if="isLoggedIn && user"
    >
        <div>
            <UserAvatar
                :title="user.email"
                :size="32"
                :avatar-url="user.avatarUrl"
                :name="[user.firstName, user.lastName]"
                :label="user.label"
                @dblclick="onCopy(user?.email ?? '')"
                class="mr-3"
            ></UserAvatar>
            <span
                class="ms-1 flex-grow-1"
                :title="user.email"
            >
                    {{ `${user.label}` }}
            </span>
        </div>
    
        <MembershipSelectorInput
            v-if="user.availableMembershipsSiteData && user.availableMembershipsSiteData.length"
        ></MembershipSelectorInput>
    </div>
</template>

<script lang="ts" setup>
    import UserAvatar from '~/components/user/UserAvatar/UserAvatar.vue';
    import MembershipSelectorInput from '~/components/controls/MembershipSelectorInput/MembershipSelectorInput.vue';
    import {
        useTextUtils,
        useCustomAuth
    } from '~/composables';

    const { sessionObj: user, isLoggedIn } = useCustomAuth();

    function onCopy(text: string): void {
        useTextUtils().copyToClipboard(text);
    }
</script>

<style lang="scss"></style>
